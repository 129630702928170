@font-face {
  font-family: 'Rubik-Regular';
  src: local('Rubik-Regular'), url(./fonts/Rubik-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f9fbfd !important ;
}
button:focus { outline: none !important;;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width:991px){
  .cardLogin{
    margin-top: 0px !important;
  }
  .loginLeftM{
    width: 100% !important;
  }
  .loginRow{
    margin: 0px !important;
  }
  .deleteModal{
    width: 80% !important;
  }
  /* main.MuiBox-root.css-1v5u2yv {
    padding: 0px !important;
      } */
}
.infinite-scroll-component{
  overflow: hidden !important;
}

.inputFieldClass{
  width: 100%;
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 4px;
}
.inputFieldClass:focus-within{
  outline: none !important;
  border-color: #0065b5 !important;
}
.ReactTags__selected {
  margin-top: 20px;
}
.tagClass{
  margin: 5px 10px;
  padding: 5px;
  color: grey;
  background-color: #ededed;
  border-radius: 4px;

}
span.tag-wrapper.tagClass{
  display: inline-block;
}
.removeClass{
  color: black;
  border:none;
  background-color: rgba(141, 136, 131,0);
  margin-left: 10px;
}

.loginLeft{
  /* background-image: url(./assets/images/background.png); */
  background-size: cover;
  background-position: center;
}


/* input#outlined-basic{
  background-color: white;
}
.MuiDataGrid-main{
  background-color: white;
}

.MuiDataGrid-footerContainer{
  background-color:'white';
}
.MuiBox-root.css-15x89za{
  background-color:#f7f7f7 !important ;
} */

main.MuiBox-root.css-1v5u2yv{
  min-height: 100vh !important;
}
/* .MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root {
  background-color: white;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: white;
}
.MuiDataGrid-root.css-xfmtxh-MuiDataGrid-root {
  background-color: white;
}

.MuiFormControl-root.MuiFormControl-fullWidth.css-tzsjye {
  background-color: white;
} */

.MuiTabs-indicator{
background-color: #010b40 !important;
height: 3px !important;
border-radius: 7px;
}

@media only screen 
and (min-width:991px)
and (max-width:1700px)
{
  .catBtns{
    margin-left: 0px !important;
  }
}

